.header {
  background-color: #ffffff;
  padding: 1.2rem;
  position: fixed;
  width: 100%;
  z-index: 2;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
}

.logo {
  width: 80px;
  height: 50px;
  border-radius: 5px;
}

.nav-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.nav-list.active {
  display: block;
}

.nav-item {
  position: relative;
  margin-right: 15px;
}

.nav-link {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 20px;
  display: block;
}

.nav-link:hover {
  background-color: #568db0;
  border-radius: 10px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
  min-width: 250px;
  border-radius: 4px;
  z-index: 3;
}

.dropdown-menu.active {
  display: block;
}

.dropdown-menu li {
  border-top: 1px solid #555;
}

.dropdown-menu li:first-child {
  border-top: none;
}

.dropdown-menu a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
}

.dropdown-menu a:hover {
  background-color: #555;
}

.nav-item.dropdown.open .dropdown-menu {
  display: block;
}

.toggle-button {
  display: none;
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 25px;
}

@media (max-width: 768px) {
  .nav-list {
    flex-direction: column;
    display: none;
    width: 100%;
  }

  .nav-list.active {
    display: flex;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    padding: 0.5rem 1rem;
    text-align: center;
  }

  .dropdown-menu {
    min-width: 100%;
    position: relative;
    margin: 0;
  }

  .toggle-button {
    display: block;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .header {
    height: auto;
    padding: 1rem;
  }

  .logo {
    width: 90px;
    height: 40px;
  }

  .nav-link {
    font-size: 18px;
  }

  .nav-link:hover {
    background-color: transparent;
    color: #568db0;
  }
}
