body {
  font-family: "Open Sans", sans-serif;
}

.expertise-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.expertise-heading {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.expertise-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 40px;
}

.expertise-buttons button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
  width: 140px;
}

.expertise-buttons button.active {
  background-color: #0056b3;
}

.expertise-buttons button:hover {
  background-color: #0056b3;
}

.expertise-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 80px;
}

.expertise-text {
  max-width: 600px;
  margin-left: 50px;
}

.expertise-text h3 {
  font-size: 2.2rem;
  margin-bottom: 10px;
}

.expertise-text p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.expertise-text ul {
  list-style-type: none;
  padding: 0;
}

.expertise-text ul li {
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
}

.expertise-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .expertise-content {
    flex-direction: column;
    text-align: center;
  }

  .expertise-buttons button {
    padding: 8px 15px;
    margin: 0 5px;
  }
.expertise-text {
  margin-left: 0;
}

.expertise-text h3 {
  font-size: 1.8rem;
}

.expertise-text p {
  font-size: 1rem;
}

.expertise-text ul li {
  font-size: 0.9rem;
}

.expertise-image img {
  margin-left: 0;
  margin-top: 20px;
}
}

@media (max-width: 480px) {
  .expertise-heading {
    font-size: 2rem;
  }

  .expertise-buttons button {
    padding: 6px 10px;
    font-size: 0.9rem;
    width: 100px;
  }

  .expertise-text h3 {
    font-size: 1.5rem;
  }

  .expertise-text p {
    font-size: 0.9rem;
  }

  .expertise-text ul li {
    font-size: 0.8rem;
  }

  .expertise-image img {
    max-width: 80%;
    margin-top: 15px;
  }
}
