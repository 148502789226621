/* Base Styles */
.about-section {
  /* background-image: linear-gradient(180deg, rgba(48, 85, 153, 255), rgb(255, 255, 255)); */
  background-color: #f9f9f9;

  color: #000000;
  text-align: center;
  padding: 4rem 2rem;
  min-height: 750px;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutLeft,
.aboutRight {
  flex: 1;
  width: 100%;
  margin-bottom: 2rem;
}

.about-heading {
  font-size: 2rem;
  margin-top: 50px;
  color: #0f0f0f;
}

.heading-underline {
  width: 10%;
  height: 5px;
  background: linear-gradient(
    to right,
    rgb(253, 138, 37) 50%,
    rgb(33, 245, 57) 40%
  );
  margin: 1rem auto;
}

.about-text {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 2rem 0;
  width: 90%;
  margin-top: 80px;
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  font-family: "Open Sans", sans-serif;
  text-align: left;
}

.about-icons {
  display: flex;
  justify-content: left;
  
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
}

.icon-text {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
}

.icon-description {
  font-size: 1rem;
  padding: 5px;
  text-align: center;
}

.aboutrightimage {
  margin-top: 50px;
  width: 100%;
  max-width: 640px;
  height: auto;
  border-radius: 20px;
  opacity: 0.8;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .aboutContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .aboutLeft,
  .aboutRight {
    width: 48%;
    margin-bottom: 0;
  }

  .about-heading {
    font-size: 2.5rem;
  }

  .about-text {
    font-size: 1.4rem;
    width: 80%;
  }

  .icon-text {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .about-section {
    padding: 2rem 1rem;
  }

  .about-heading {
    font-size: 1.8rem;
    margin-top: 20px;
  }

  .about-text {
    font-size: 1rem;
    width: 100%;
    margin-top: 20px;
  }

  .icon-text {
    font-size: 1.2rem;
  }

  .aboutrightimage {
    margin-top: 20px;
  }
}
