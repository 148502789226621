/* WhyChooseUs.css */

.why-choose-us {
    padding: 10px;
    background-color: #ffffff;
    text-align: center;
    
  }
  
  .why-choose-us h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  }
  
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 80px;
  }
  
  .card {
    flex: 1 1 calc(33.333% - 40px); /* Adjust this for responsive design */
    max-width: calc(33.333% - 40px); /* Adjust this for responsive design */
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: transform 0.2s, box-shadow 0.2s;
    margin: 10px;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card p {
    margin: 0;
    font-size: 1.1rem;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .card {
      flex: 1 1 calc(50% - 40px); 
      max-width: calc(50% - 40px); 
    }
  }
  
  @media (max-width: 480px) {
    .card {
      flex: 1 1 100%; 
      max-width: 100%; 
    }
  }
  