/* .contact-us {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #0c151f;
}

.contact-details,
.services-section,
.contact-image {
  flex: 1;
  margin: 10px;
}

.contact-details h2,
.services-section h3 {
  margin-bottom: 20px;
  font-size: 30px;
  color: aliceblue;
}

.leftcontact {
  max-width: 300px;
}

.leftcontact p {
  margin: 5px 0;
  color: aliceblue;
  padding: 10px;
  font-size: large;
}

.services-section ul {
  list-style: none;
  padding: 0;
}

.services-section li {
  margin: 10px 0;
  font-size: 18px;
}

.services-section a {
  text-decoration: none;
  color: #007bff;
}

.services-section a:hover {
  text-decoration: underline;
}

.contact-image img {
  max-width: 100%;
  height: auto;
}

.emailcontact {
  text-decoration: none;
  color: aliceblue;
}

.emailcontact:hover {
  color: #007bff;
}

.service-icon {
  margin-right: 8px;
  color: aliceblue;
}

@media (max-width: 768px) {
  .contact-us {
    flex-direction: column;
    align-items: center;
  }

  .contact-details,
  .services-section,
  .contact-image {
    margin: 10px 0;
  }
} */

.contact-us {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #000000;
}

.logo-section {
  flex: 1;
  text-align: left;
}

.company-logo {
  max-width: 100px;
  height: auto;
}

.company-name {
  font-size: 1.5rem;

  margin-left: 30px;
}

.contact-details,
.services-section,
.contact-image {
  flex: 1;
  margin: 10px;
}

.contact-details h2,
.services-section h2 {
  margin-bottom: 15px;
  color: aliceblue;
  margin-left: 15px;
}

.leftcontact {
  max-width: 300px;
}

.leftcontact p {
  margin: 5px 0;
  color: aliceblue;
  padding: 10px;
}

.services-section ul {
  list-style: none;
  padding: 0;
}

.services-section li {
  margin: 10px 0;
}

.services-section a {
  text-decoration: none;
  color: #007bff;
}

.services-section a:hover {
  text-decoration: underline;
}

.contact-image img {
  max-width: 100%;
  height: auto;
}

.logo-section {
  margin-top: -10px;
}

.footerlogo {
  width: 200px;
  height: 140px;
  border-radius: 8px;
}

.company-name {
  color: rgb(252, 252, 252);
  margin: 0px;
}

.spantwo {
  color: white;
}

.service-icon {
  margin-right: 8px;
  color: aliceblue;
}

.emailcontact {
  text-decoration: none;
  color: aliceblue;
}

.emailcontact:hover {
  color: #007bff;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.footer-line {
  border: 0;
  height: 1px;
  background: #007bff;
  margin: 20px 120px;
}

.footer-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.copyright {
  color: aliceblue;
  margin: 0;
}

.policy-links {
  display: flex;
  align-items: center;
}

.policy-link {
  color: #ffffff;
  text-decoration: none;
}

.policy-link:hover {
  color: #007bff;
}

.dividerspan {
  color: aliceblue;
  margin-left: 8px;
  margin-right: 8px;
}
@media (max-width: 768px) {
  .contact-us {
    flex-direction: column;
    align-items: center;
  }

  .contact-details,
  .services-section,
  .contact-image {
    margin: 10px 0;
  }

  .footer-text {
    flex-direction: column;
    align-items: center;
  }

  .policy-links {
    margin-top: 10px;
    align-items: center;
  }
}
