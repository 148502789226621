.main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 4rem;
  background-color: #ffffff;
  height: 620px;
  background-image: url("../../../public/Assets/mhb211.jpg");
  opacity: 0.9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* box-sizing: border-box; */
}

.oceantechhome {
  height: 180px;
}

.left-part {
  flex: 1;

  padding: 1rem;
}

.left-part h1 {
  padding-top: 150px;
  font-size: 2.5rem;
  color: #ffffff;
  font-weight: bold;
  /* animation: text 3s 1; */
}

/* @keyframes left-part {
  0%{
    color: #000;
    margin-bottom : -40px;
  }
  30%{
    color: #000;
    margin-bottom : -40px;
  }
} */

.left-part p {
  font-size: 1.4rem;
  margin-bottom: 3rem;
  font-weight: bolder;
  color: rgb(255, 255, 255);
}
.span {
  color: #fa9d32;
}

.start-button {
  /* background-color: #56bae4; */
  background-color: #fa9d32;
  color: white;
  padding: 0.75rem 1.8rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
}

.start-button:hover {
  background-color: #ffffff;
  color: #222222;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .main-section {
    padding: 3rem 2rem;
    height: auto;
    align-items: center;
  }

  .left-part,
  .right-part {
    width: 100%;
    margin-bottom: 2rem;
  }

  .left-part h1 {
    font-size: 2rem;
  }

  .left-part p {
    font-size: 1.2rem;
  }

  .start-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .main-section {
    padding: 2rem 1rem;
    height: auto;
    align-items: center;
  }

  .left-part,
  .right-part {
    width: 100%;
    margin-bottom: 1rem;
  }

  .left-part h1 {
    font-size: 1.8rem;
  }

  .left-part p {
    font-size: 1rem;
  }

  .start-button {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 480px) {
  .main-section {
    padding: 1rem;
    height: auto;
    align-items: center;
  }

  .left-part,
  .right-part {
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
    margin-top: 20px;
  }

  .left-part h1 {
    padding-top: 20px;
    font-size: 1.5rem;
  }

  .left-part p {
    font-size: 0.9rem;
  }

  .start-button {
    font-size: 0.7rem;
    padding: 0.4rem 0.8rem;
  }
}
