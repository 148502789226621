body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.testimonials-section {
  background-color: #f9f9f9;
  padding: 100px 0;
  text-align: center;
  height: 700px;
}

.testimonials-container {
  max-width: 1300px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.section-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.section-subtitle {
  font-size: 1.5rem;
  color: #777;
  margin-bottom: 50px;
}

.testimonials-slider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.testimonials-track {
  display: flex;
  width: calc(210%); /* Adjusted to fit the duplicated content */
  animation: scroll 20s linear infinite;
}

.testimonial-card {
  background-color: #fefcfc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin: 0 10px;
  width: 300px;
  height: 360px;
  flex-shrink: 0;
  text-align: center;
}

.profile-icon {
  font-size: 3rem;
  color: #667eea;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}

.testimonial-card h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 5px;
}

.testimonial-card .designation {
  font-size: 1rem;
  color: #777;
  margin-bottom: 10px;
}

.testimonial-card .comment {
  font-size: 1rem;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  color: #555;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
