.service-page {
  padding: 40px;
  text-align: center;
}

.service-heading {
  font-size: 2.5rem;
  margin-top: 80px;
  margin-bottom: 20px;
  color: #333;
}

.service-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.service-image {
  flex: 1 1 40%;
  max-width: 40%;
  margin-right: 100px;
}

.service-image img {
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.service-details {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 20px;
  text-align: left;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .service-content {
    flex-direction: column;
  }

  .service-image,
  .service-details {
    max-width: 100%;
    margin: 0;
  }

  .service-image {
    margin-bottom: 20px;
  }
}
