.linkBar {
  width: 265px;
  height: 44px;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
  border-radius: 30px;
  box-shadow: 0px 4px 12px lightblue;
  z-index: 2;
  display: flex;
    align-items: center;
    justify-content: center;
    /* Center horizontally */
}

.icon {
  width: 55px;
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
    transition: transform 0.6s ease;
}

.icon:hover {
  transform: scale(1.2);
}
