body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.industries-section {
  background-color: #f4f4f4;
  padding: 100px 0;
  text-align: center;
  background-image: linear-gradient(
    180deg,
    rgb(255, 255, 255),
    rgb(205, 218, 255)
  );
}

.industries-container {
  max-width: 1000px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.industries-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding: 0 20px;
  margin-top: 50px;
}

.industry-card {
  background-image: linear-gradient(
    180deg,
    rgb(255, 255, 255),
    rgb(221, 232, 241)
  );
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
    justify-content: center;
    flex-direction: column;
}

.industry-card p {
  font-size: 1.2rem;
  color: #333;
  margin-top: 10px;
}

.industry-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.heading-underline {
  width: 10%;
  height: 5px;
  background: linear-gradient(
    to right,
    rgb(253, 138, 37) 50%,
    rgb(33, 245, 57) 40%
  );
  margin: 1rem auto;
}

.industryicon {
  width: 40px;
  height: 40px;
  margin-top: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .section-title {
    font-size: 2rem;
  }

  .industries-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .industry-card {
    padding: 15px;
  }

  .industry-card p {
    font-size: 1rem;
  }

  .industryicon {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.8rem;
  }

  .industries-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .industry-card {
    padding: 10px;
  }

  .industry-card p {
    font-size: 0.9rem;
  }

  .industryicon {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.5rem;
  }

  .industries-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .industry-card {
    padding: 10px;
  }

  .industry-card p {
    font-size: 0.8rem;
  }

  .industryicon {
    width: 25px;
    height: 25px;
  }
}
