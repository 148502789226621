body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.clients-section {
  background-color: #fff;
  padding: 50px 0;
  text-align: center;
  position: relative;
}

.clients-container {
  max-width: 1300px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.section-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.section-subtitle {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

.clients-slider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.clients-track {
  display: flex;
  width: calc(200%); /* Adjusted to fit the duplicated content */
  animation: scroll 20s linear infinite;
  margin-top: 30px;
  height: 120px;
}

.client-logo {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 0 20px;
  min-width: 150px;
  flex-shrink: 0;
  text-align: center;

}

.client-logo img {
  max-width: 100px;
  height: auto;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
